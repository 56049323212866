import gsap from 'gsap'
import { TimelineMax } from 'gsap/all'
import { ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(TimelineMax)

// VARIABLES

const themeButton = document.querySelector('.color-scheme-button')
let date = document.querySelector('.date')

const menuButton = document.querySelector('.menu-icon')
const menu = document.querySelector('.menu')
const menuChoices = document.querySelectorAll('.menu ul')
const roundRight = document.querySelector('.round-right')

// LIGHT & DARK THEME

themeButton.addEventListener('click', (event) => {

	let schemeColor = document.documentElement.attributes[1].nodeValue

	if(schemeColor == "light"){
		transition()
		themeButton.style.backgroundColor = "#151515"
		document.documentElement.setAttribute('data-theme', 'dark')
	}else{
		transition()
		themeButton.style.backgroundColor = "#D0D9CE"
		document.documentElement.setAttribute('data-theme', 'light')
	}

	themeButton.classList.add('scale')
	window.setTimeout(() => {
		transition()
		themeButton.classList.remove('scale')
	}, 1000)
})

const transition = () => {
	document.documentElement.classList.add('transition')
	window.setTimeout(() => {
		document.documentElement.classList.remove('transition')
	}, 1000)
}

// END THEME

// TIME

let time = new Date();

date.insertAdjacentHTML('beforeend', `${time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`)

// END TIME

// MENU

menuButton.addEventListener('click', (event) => {
	const tl = new TimelineMax({repeat: 0})

	menu.classList.toggle('visible')

	roundRight.classList.toggle('menu-circle')
})

menuChoices.forEach((menuChoice) => {
	menuChoice.addEventListener('click', (event) => {
		menu.classList.remove('visible')
		roundRight.classList.remove('menu-circle')
	})	
})



// END MENU

// SCROLL ANIMATIONS

// landing

gsap.to('.title', {
	scrollTrigger: {
		trigger: '.about', 
		start: 'top center',
		scrub: true
	}, 
	y: -1000
})

gsap.to('.date', {
	scrollTrigger: {
		trigger: '.about', 
		start: 'top center',
		scrub: true
	}, 
	x: -1000
})

gsap.to('.color-scheme-button', {
	scrollTrigger: {
		trigger: '.about', 
		start: 'top center',
		scrub: true
	}, 
	x: 250
})

// about
gsap.to('.horizontal-line', {
	scrollTrigger: {
		trigger: '.landing',
		start: 'bottom bottom',
		scrub: true
	},
	left: 0
})

const tl = gsap.timeline({
	scrollTrigger: {
		trigger: '.about', 
		start: 'top top',
		scrub: true,
		pin: true
	}
})

console.log(window.innerWidth)

if(window.innerWidth < "700"){
	tl
		.to('.about-title', {opacity: 0})
		.from('.content-image', {x: "-120vh"})
		.from('.content-intro', {x: "120vh"})
		.from('.content-main', {x: "-120vh"})
}else{
	tl
		.to('.about-title', {x: "-50vh", opacity: 0})
		.from('.content-image', {x: "-120vh"})
		.from('.content-intro', {y: "-120vh"})
		.from('.content-main', {x: "120vh"})
	}

// work	

gsap.to('.icon-asterisk', {
	scrollTrigger: {
		trigger: '.about',
		start: 'center top',
		scrub: true
	},
	rotation: 360
})

gsap.from('.work-title-writting', {
	scrollTrigger:{
		trigger: '.about',
		start: 'center top'
	},
	opacity: 0,
	duration: 3,
	ease: "power4"
})

// contact

const timeline = gsap.timeline({
	scrollTrigger: {
		trigger: '.work', 
		start: 'center top',
		scrub: true
	}
})

if(window.innerWidth > "700"){
	timeline
	.staggerFrom('.hidetext', 3, {
		y: "100%",
		ease: 'power4'
	}, 0.5)
}





// END SCROLL








