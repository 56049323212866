const body = document.querySelector('body')
const cursor = document.querySelector('.cursor')
const links = document.querySelectorAll('.hover-this')

const editCursor = e => {
	const { clientX: x, clientY: y } = e

	cursor.style.left = x + 'px'
	cursor.style.top = y + 'px'
}

const animateCursor = function (e) {

	cursor.style.transform = 'scale(3)'

	if(e.type == 'mouseleave'){
		cursor.style.transform = 'scale(1)'
	}
}


if(window.innerWidth > "1000"){
	window.addEventListener('mousemove', editCursor)

	links.forEach((link) => {
		link.addEventListener('mouseenter', animateCursor)
	})

	links.forEach((link) => {
		link.addEventListener('mouseleave', animateCursor)
	})
}else{
	cursor.style.display = "none"

	body.style.cursor = "default"
}
